import * as API from "@/api/dashboard";
import * as Share from "@/api/share";
import renderModal from "@/utils/render-modal";
import { memoize } from "@/utils/function";
import DefaultAssembly from "../assembly";
import * as Widgets from "../widgets";
import ModelWidget from "../models/widget";

export function configWidget(defaultWidget) {
  const WidgetAssembly = Widgets.getAssembly(defaultWidget.type);
  return renderModal(WidgetAssembly || DefaultAssembly, { defaultWidget });
}

/** @type {(datasheetId: string) => Promise<import("axios").AxiosResponse<any>>} */
export const getDatasheetColumns = memoize(function(datasheetId) {
  return API.getDatasheetColumns({ datasheetId });
});

/** @type {(data: object) => Promise<import("axios").AxiosResponse<any>>} */
export const getDistinctValue = memoize(API.getDistinctValue, data =>
  JSON.stringify(data)
);

/** @type {(data: object) => Promise<import("axios").AxiosResponse<any>>} */
export const getWidgetData = memoize(
  data => {
    if (data && data.sharedId) {
      return Share.getWidgetData(data);
    }
    return API.getWidgetData(data);
  },
  data => JSON.stringify(data)
);

export function clearCache() {
  getDatasheetColumns.cache.clear();
  getDistinctValue.cache.clear();
  getWidgetData.cache.clear();
}

export function normalizeLayout(layout) {
  layout = layout || [];
  return layout.map(ModelWidget.from);
}

/**
 * @param {import("../widgets/type").IWidgetDatasource} datasource
 */
export function validateDatasource(datasource) {
  datasource = datasource || {};
  const validDatasheet = !!datasource.datasheetId;
  const columns = datasource.columns || [];
  const validDimensions = columns.every(item => item.columnId);
  return validDatasheet && validDimensions && columns.length;
}

export { default as autoLayout } from "./auto-layout";

export { default as error } from "./error";

export { default as themeProvider } from "../atoms/select-theme/theme-provider";
