var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-item"},[_c('div',{staticStyle:{"display":"flex"}},[(_vm.taskItem.type == 'dingtalk')?_c('img',{attrs:{"src":"images/app_dingtalk.svg","alt":""}}):_c('img',{attrs:{"src":"images/app_wechat.svg","alt":""}}),_c('div',{staticStyle:{"margin-left":"7px","margin-top":"4px"}},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.taskItem.name))]),(_vm.taskListStutasItem.latestSyncJobStatus)?_c('div',{staticClass:"item-time"},[_c('svg',{staticStyle:{"padding-top":"4px","vertical-align":"baseline"},attrs:{"viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":16,"height":16}},[_c('path',{attrs:{"d":"M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z","fill":_vm.taskListStutasItem.latestSyncJobStatus == 'failed'
                ? '#ed3f14'
                : _vm.taskListStutasItem.latestSyncJobStatus == 'succeeded'
                ? '#19be6b'
                : '#1890ff',"fill-opacity":".25"}}),_c('path',{attrs:{"d":"M512 512m-398.222222 0a398.222222 398.222222 0 1 0 796.444444 0 398.222222 398.222222 0 1 0-796.444444 0Z","fill":_vm.taskListStutasItem.latestSyncJobStatus == 'failed'
                ? '#ed3f14'
                : _vm.taskListStutasItem.latestSyncJobStatus == 'succeeded'
                ? '#19be6b'
                : '#1890ff'}}),_c('path',{attrs:{"d":"M483.555556 483.555556m-312.888889 0a312.888889 312.888889 0 1 0 625.777777 0 312.888889 312.888889 0 1 0-625.777777 0Z","fill":"#F1F6F8","opacity":".3"}}),_c('path',{attrs:{"d":"M625.777778 455.111111m-56.888889 0a56.888889 56.888889 0 1 0 113.777778 0 56.888889 56.888889 0 1 0-113.777778 0Z","fill":"#F1F6F8","opacity":".5"}})]),_c('span',[_vm._v(" 最近推送："+_vm._s(_vm.taskListStutasItem.latestSyncJobCreatedAt)+" ")])]):_c('div',{staticClass:"item-time"},[_c('span',[_vm._v(" 暂无推送记录")])])])]),_c('div',[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("设置")])]),_c('i',{staticClass:"ndl-icon-settings icon-class",on:{"click":function($event){return _vm.showChange(2)}}})],2),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("立即推送")])]),_c('img',{staticClass:"icon-class",attrs:{"src":"images/urgent-letter.svg","alt":""},on:{"click":_vm.runDatasheetApp}})],2),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("推送历史")])]),_c('i',{staticClass:"ndl-icon-history-line icon-class",on:{"click":function($event){return _vm.showChange(3)}}})],2),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("删除")])]),_c('i',{staticClass:"ndl-icon-x-circle icon-class",staticStyle:{"color":"#D25F00"},on:{"click":_vm.clickDeleteGroup}})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }