<template>
  <div class="pane-side">
    <div class="tabs">
      <div :class="['tab-item', { active: !toggle }]" @click="toggle = false">
        图表数据
      </div>
      <div :class="['tab-item', { active: toggle }]" @click="toggle = true">
        图表样式
      </div>
    </div>
    <component
      class="side-content"
      :is="Content"
      :widget="widget"
      :datasource="widget.datasource || {}"
      :options="widget.options || {}"
    />
  </div>
</template>

<script>
import * as Widgets from "../widgets";

export default {
  inheritAttrs: false,
  props: {
    /** @type {import("../models/widget").default} */
    widget: Object
  },
  data() {
    return {
      toggle: false
    };
  },
  computed: {
    Content() {
      const widgetType = this.widget.type;
      return this.toggle
        ? Widgets.getDecoration(widgetType)
        : Widgets.getDatasource(widgetType);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
@import "./vars.less";

.pane-side {
  width: @pane-side-width;
  border-left: @pane-border;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  .tabs {
    flex-shrink: 0;
    display: flex;
    border-bottom: @pane-border;
  }
  .tab-item {
    flex: 1 1 0;
    width: 0;
    padding: 12px;
    text-align: center;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    &:hover,
    &.active {
      color: @blue-6;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      @width: 4em;
      width: @width;
      left: 50%;
      margin-left: -@width / 2;
      height: 2px;
      transition: background-color 0.3s;
    }
    &.active::after {
      background: @blue-6;
    }
  }
  .side-content {
    flex: 1 1 0;
    overflow: auto;
    padding: 22px 18px;
  }
}
</style>
