import renderModal from "@/utils/render-modal";
import Share from "./Share";

/**
 * @typedef {{ node: object }} Options
 * @param {Options} opts
 * @returns {Promise<any>}
 */
export default function(dashboardId) {
  return renderModal(Share, { dashboardId });
}
