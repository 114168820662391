<template>
  <div class="share">
    <QRCoderHeader
      @changeShowPage="changeShowPage"
      :dashboardId="dashboardId"
    ></QRCoderHeader>
    <QRCoderBody
      @getQrcodeId="getQrcodeId"
      :dashboardId="dashboardId"
    ></QRCoderBody>
    <QRCoderFooter @changeShowPage="changeShowPage"></QRCoderFooter>
  </div>
</template>

<script>
import QRCoderHeader from "./qrcode/QRCoderHeader.vue";
import QRCoderBody from "./qrcode/QRCoderBody.vue";
import QRCoderFooter from "./qrcode/QRCoderFooter.vue";

export default {
  components: { QRCoderHeader, QRCoderBody, QRCoderFooter },
  props: {
    dashboardId: String
  },
  data() {
    return {};
  },
  methods: {
    changeShowPage(index) {
      this.$emit("changeShowPage", index);
    },
    getQrcodeId(id) {
      this.$emit("getQrcodeId", id);
    }
  }
};
</script>
<style lang="less" scoped></style>
