<template>
  <div class="pane-main">
    <a-empty
      v-if="!datasourceReady"
      :image="EMPTY_IMAGE"
      :image-style="{ height: '280px' }"
      description="请配置图表数据"
    />
    <component
      v-if="modalReady"
      ref="playground"
      :is="Playground"
      :widget="widget"
    />
  </div>
</template>

<script>
import EMPTY_IMAGE from "@/assets/images/ai_visualize.svg";
import debounce from "lodash/debounce";
import * as Widgets from "../widgets";
import * as utils from "../utils";

export default {
  inheritAttrs: false,
  props: {
    /** @type {import("../models/widget").default} */
    widget: Object
  },
  data() {
    return {
      EMPTY_IMAGE,
      modalReady: false,
      datasourceReady: utils.validateDatasource(this.widget.datasource)
    };
  },
  computed: {
    Playground() {
      return Widgets.getPlayground(this.widget.type);
    }
  },
  mounted() {
    // mounted 时组件可能尚未挂载到 document 中
    this.$nextTick(() => (this.modalReady = true));
  },
  watch: {
    "widget.datasource": {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    "widget.options": {
      deep: true,
      handler() {
        this.redraw();
      }
    }
  },
  methods: {
    refresh: debounce(
      function() {
        this.datasourceReady = utils.validateDatasource(this.widget.datasource);
        if (!this.datasourceReady) {
          return;
        }
        /** @type { refresh() => void } */
        const ref = this.$refs.playground;
        if (ref && ref.reload) {
          ref.reload();
        }
      },
      100,
      { leading: false, trailing: true }
    ),
    redraw: debounce(function() {
      /** @type { refresh() => void } */
      const ref = this.$refs.playground;
      if (ref && ref.draw) {
        ref.draw();
      }
    }, 300)
  }
};
</script>

<style lang="less" scoped>
.pane-main {
  flex: 1 1 0;
  overflow: hidden;
  position: relative;
  .ant-empty {
    position: absolute;
    inset: 0;
    z-index: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
  }
}
</style>
