<template>
  <a-modal width="680px" :footer="null" centered>
    <a-result
      :title="`${shareLink ? '已' : ''}分享「${dashboardName}」`"
      :sub-title="shareLink ? null : '生成公开的分享链接，所有人可见'"
      style="height: 500px"
    >
      <img slot="icon" :src="ImageShare" style="height: 240px" />
      <Auth
        v-if="shareLink"
        slot="extra"
        v-model="config.shareAuth"
        @change="onChangeAuthType()"
      />
      <Link
        v-if="shareLink"
        slot="extra"
        :link="shareLink"
        @cancel="onCancel()"
      />
      <a-button
        v-else
        :loading="loading"
        slot="extra"
        type="primary"
        @click="onShare()"
      >
        生成分享链接
      </a-button>
    </a-result>
  </a-modal>
</template>

<script>
import * as API from "@/api/dashboard";
import workspace from "@/views/datamodel/models/workspace";
import ImageShare from "@/assets/images/ai_share.svg";
import Auth from "./Auth";

export default {
  components: {
    Auth,
    Link: () => import(/* webpackChunkName: "share-link" */ "./Link")
  },
  props: {
    dashboardId: String
  },
  data() {
    return {
      ImageShare,
      loading: true,
      config: {}
    };
  },
  computed: {
    dashboardName() {
      const node = workspace.getNodeById(this.dashboardId);
      return node && node.nodeName;
    },
    shareLink() {
      const sharedId = this.config.id;
      if (sharedId) {
        const url = new URL(`/share/dashboard/${sharedId}`, location);
        return url.href;
      }
      return null;
    }
  },
  created() {
    this.getShareInfo();
  },
  methods: {
    async getShareInfo() {
      this.loading = true;
      // 神奇的接口，分享竟然返回数组～将就着用吧
      const { data } = await API.getDashboardSharedInfo(this.dashboardId);
      this.config = data[0] || {};
      this.loading = false;
    },
    async onShare(shareAuth) {
      this.loading = true;
      try {
        const dashboardId = this.dashboardId;
        const { data } = await API.shareDashboard({ dashboardId, shareAuth });
        this.config = data;
      } catch {
        // 其他人已经分享过时，重复分享接口会报错，懒得处理接口，前端将就用一下吧
        await this.getShareInfo();
      } finally {
        this.loading = false;
      }
    },
    onChangeAuthType() {
      this.onCancel(false);
      this.onShare(this.config.shareAuth);
    },
    onCancel(clear = true) {
      const dashboardId = this.dashboardId;
      const sharedId = this.config.id;
      API.unshareDashboard({ dashboardId, sharedId });
      if (clear) {
        this.config = {};
      }
    }
  }
};
</script>
