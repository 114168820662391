<template>
  <div class="dashboard">
    <Header :layout="layout" class="dashboard-header" />
    <div class="dashboard-body">
      <Layout
        v-model="layout"
        @config="onWidgetConfig"
        @redirect="onWidgetRedirect"
        @delete="onWidgetDelete"
        @change="saveLayout"
      />
      <a-empty
        v-if="!layout.length"
        :image="EMPTY_IMAGE"
        :image-style="{ height: '260px', marginTop: '100px' }"
      >
        <div slot="description" style="color: #bfbfbf; margin-top: 50px">
          请添加组件
        </div>
      </a-empty>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/dashboard";
import EMPTY_IMAGE from "@/assets/images/ai_dashboard.svg";
import workspace from "@/views/datamodel/models/workspace";
import Header from "./Header";
import Layout from "./layout";
import * as utils from "./utils";

export default {
  components: { Header, Layout },
  data() {
    return { EMPTY_IMAGE, layout: [] };
  },
  created() {
    this.getLayoutData();
  },
  destroyed() {
    utils.clearCache();
  },
  watch: {
    "$route.params.datasheetId"() {
      this.getLayoutData();
    }
  },
  methods: {
    async getLayoutData() {
      const id = this.$route.params.datasheetId;
      const node = workspace.getNodeById(id);
      if (!node) {
        this.layout = [];
        return;
      }
      node.nodeStatus.loading = true;
      try {
        const { data } = await API.getDashboard(id);
        this.layout = utils.normalizeLayout(data.layout);
      } finally {
        node.nodeStatus.loading = false;
      }
    },
    async onWidgetConfig(widget) {
      await utils.configWidget(widget);
      this.saveLayout();
    },
    onWidgetRedirect(widget) {
      const datasource = widget.datasource || {};
      const node = workspace.getNodeById(datasource.datasheetId);
      if (node) {
        this.$router.push({
          name: "datamodel-workspace",
          params: {
            workspaceId: node.parentId,
            datasheetId: node.nodeId
          }
        });
      }
    },
    onWidgetDelete(widget) {
      this.layout = this.layout.filter(item => item !== widget);
    },
    saveLayout() {
      const id = this.$route.params.datasheetId;
      const layout = this.layout;
      API.saveDashboard({ id, layout });
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.dashboard {
  width: 100%;
  height: 100%;
  background: @dashboard-background;
  display: flex;
  flex-direction: column;
  .dashboard-header {
    flex-shrink: 0;
    padding-bottom: 8px;
  }
  .dashboard-body {
    flex: 1 1 0;
    overflow: auto;
    padding-bottom: 200px;
  }
}
</style>
