<template>
  <a-modal width="567px" :footer="null" centered>
    <template slot="title">
      <div class="title">
        <img
          v-if="
            (title == '选择应用' && taskListData.length) ||
              title == '群推送历史记录'
          "
          @click="cance"
          style="vertical-align: baseline;cursor: pointer;margin-right:15px"
          src="images/share_goback.svg"
          alt=""
        />
        <span>
          {{ title }}
        </span>
      </div>
    </template>
    <div v-if="taskListData.length">
      <div v-if="show == 1">
        <TaskList
          v-if="showDataList"
          ref="TaskList"
          @goback="goback"
          @changeTitle="changeTitle"
          @showChange="showChange"
          @deleteItem="deleteItem"
          @runDatasheetApp="runDatasheetApp"
          :dashboardId="dashboardId"
          :taskListData="taskListData"
          :taskListStutasData="taskListStutasData"
        />
        <div v-else style="height:450px">
          <div class="divider"></div>
          <a-icon
            style="font-size:30px;margin-top:180px;margin-left:calc(50% - 15px)"
            type="loading"
          />
        </div>
      </div>

      <PushSettings
        :paramsData="paramsData"
        @goback="goback"
        :dashboardId="dashboardId"
        v-if="show == 2"
      />
      <ShareHistory
        :paramsData="paramsData"
        :dashboardId="dashboardId"
        @changeTitle="changeTitle"
        @goback="goback"
        v-if="show == 3"
      />
    </div>
    <BuildTask
      :dashboardId="dashboardId"
      v-else
      @goback="goback"
      @changeTitle="changeTitle"
    />
  </a-modal>
</template>

<script>
import * as API from "@/api/share-ground";
import BuildTask from "./BuildTasks.vue";
import TaskList from "./TaskLists.vue";
import PushSettings from "./PushSettings.vue";
import ShareHistory from "./ShareHistory.vue";

export default {
  components: { BuildTask, TaskList, PushSettings, ShareHistory },
  props: {
    dashboardId: String
  },
  data() {
    return {
      title: "",
      show: 1,
      paramsData: {},
      taskListData: [],
      taskListStutasData: [],
      showDataList: false,
      interval: null
    };
  },
  created() {
    this.interval = setInterval(this.getDeliveryList, 5000);
    this.showDataList = false;
    this.getDeliveryList();
  },
  methods: {
    changeTitle(title) {
      this.title = title;
    },
    cance() {
      if (this.title == "选择应用" && this.taskListData.length) {
        this.$refs.TaskList.showList = true;
        this.$refs.TaskList.changeTitle("当前看板群推送");
      }
      if (this.title == "群推送历史记录") {
        this.show = 1;
      }
    },
    showChange(params) {
      this.paramsData = params.data;
      this.show = params.show;
    },
    goback(type) {
      this.show = 1;
      this.showDataList = false;
      this.getDeliveryList(type);
    },
    async deleteItem(item) {
      await API.deleteDatasheetConnection(item.id);
      this.showDataList = false;
      this.getDeliveryList("delete");
    },
    async runDatasheetApp(item) {
      try {
        await API.runDatasheetApp(item.id);
        setTimeout(() => {
          this.getDeliveryList("push");
        }, 0);
      } catch (error) {
        this.$message.error("请求错误");
      }
      // let data = await API.runDatasheetApp(item.id);
      // console.log(data.response.statusText,'data')
    },
    async getDeliveryList(type) {
      const { data } = await API.getDeliveryList(this.dashboardId);
      this.taskListData = this.taskListData.splice(0, this.taskListData.length);
      this.taskListData = data;
      this.getDeliveryListStutas();
      if (type && type == "push") {
        this.$message.success("正在推送");
      }
      if (type && type == "submit") {
        this.$message.success("创建成功");
      }
      if (type && type == "update") {
        this.$message.success("更新成功");
      }
      if (type && type == "delete") {
        this.$message.success("删除成功");
      }
    },
    async getDeliveryListStutas() {
      const { data } = await API.getDeliveryListStutas();
      this.taskListStutasData = [];
      this.taskListData.forEach(item => {
        data.forEach(dataItem => {
          if (dataItem.id == item.id) {
            this.taskListStutasData.push(dataItem);
          }
        });
      });
      this.showDataList = true;
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
<style lang="less" scoped>
.title {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}
/deep/.ant-modal-body {
  padding: 0px;
}
.divider {
  height: 0;
  border-bottom: 1px solid #c9cdd4;
}
</style>
