<template>
  <div class="list-item">
    <div style="display:flex">
      <img
        v-if="taskItem.type == 'dingtalk'"
        src="images/app_dingtalk.svg"
        alt=""
      />
      <img v-else src="images/app_wechat.svg" alt="" />
      <div style="margin-left:7px;margin-top: 4px;">
        <div class="item-name">{{ taskItem.name }}</div>
        <div v-if="taskListStutasItem.latestSyncJobStatus" class="item-time">
          <svg
            style="padding-top: 4px;vertical-align: baseline;"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            :width="16"
            :height="16"
          >
            <path
              d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
              :fill="
                taskListStutasItem.latestSyncJobStatus == 'failed'
                  ? '#ed3f14'
                  : taskListStutasItem.latestSyncJobStatus == 'succeeded'
                  ? '#19be6b'
                  : '#1890ff'
              "
              fill-opacity=".25"
            />
            <path
              d="M512 512m-398.222222 0a398.222222 398.222222 0 1 0 796.444444 0 398.222222 398.222222 0 1 0-796.444444 0Z"
              :fill="
                taskListStutasItem.latestSyncJobStatus == 'failed'
                  ? '#ed3f14'
                  : taskListStutasItem.latestSyncJobStatus == 'succeeded'
                  ? '#19be6b'
                  : '#1890ff'
              "
            />
            <path
              d="M483.555556 483.555556m-312.888889 0a312.888889 312.888889 0 1 0 625.777777 0 312.888889 312.888889 0 1 0-625.777777 0Z"
              fill="#F1F6F8"
              opacity=".3"
            />
            <path
              d="M625.777778 455.111111m-56.888889 0a56.888889 56.888889 0 1 0 113.777778 0 56.888889 56.888889 0 1 0-113.777778 0Z"
              fill="#F1F6F8"
              opacity=".5"
            />
          </svg>
          <span>
            最近推送：{{ taskListStutasItem.latestSyncJobCreatedAt }}
          </span>
        </div>
        <div v-else class="item-time">
          <span> 暂无推送记录</span>
        </div>
      </div>
    </div>
    <div>
      <a-tooltip placement="top">
        <template slot="title">
          <span>设置</span>
        </template>
        <i @click="showChange(2)" class="ndl-icon-settings icon-class"></i>
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>立即推送</span>
        </template>
        <img
          @click="runDatasheetApp"
          class="icon-class"
          src="images/urgent-letter.svg"
          alt=""
        />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>推送历史</span>
        </template>
        <i @click="showChange(3)" class="ndl-icon-history-line icon-class"></i>
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>删除</span>
        </template>
        <i
          @click="clickDeleteGroup"
          style="color:#D25F00"
          class="ndl-icon-x-circle icon-class"
        ></i>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    taskItem: Object,
    taskListStutasItem: Object
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    showChange(index) {
      if (index == 2) {
        this.$emit("changeTitle", "群推送设置");
      }
      this.$emit("showChange", { data: this.taskItem, show: index });
    },
    clickDeleteGroup() {
      let that = this;
      let content = `是否确认要删除该群推送？`;
      this.$confirm({
        title: "提示",
        content: () => <div style="">{content}</div>,
        onOk() {
          that.deleteItem(that.taskItem);
          // that.$message.success("删除成功");
        }
      });
    },
    runDatasheetApp() {
      if (
        this.taskListStutasItem.latestSyncJobStatus &&
        this.taskListStutasItem.latestSyncJobStatus == "running"
      ) {
        this.$message.warnning("正在推送");
        return;
      }
      this.$emit("runDatasheetApp", this.taskItem);
    },
    deleteItem(taskItem) {
      this.$emit("deleteItem", taskItem);
    }
  }
};
</script>
<style lang="less" scoped>
.list-item {
  display: flex;
  justify-content: space-between;
  width: 483px;
  height: 76px;
  margin: 0 auto 25px;
  padding: 13px 0px 13px 9px;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
  img {
    width: 50px;
    height: 50px;
  }
  .item-name {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    font-weight: 500;
  }
  .item-time {
    line-height: 18px;
    color: #c9cdd4;
  }
  .history-status-light {
    display: inline-block;
    width: 12px;
    height: 12px;
    color: #000000;
    border-radius: 6px;
    background: #23c343;
  }
  .history-status-fail {
    background: rgb(237, 63, 20);
  }
  .icon-class {
    width: 14px;
    height: 14px;
    margin-right: 15px;
    line-height: 56px;
    color: #86909c;
    vertical-align: middle;
    cursor: pointer;
  }
}
</style>
