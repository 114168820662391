<template>
  <div class="history">
    <div class="share-top">
      <div @click="changeToShowPage(1)" class="share-top-title">
        <a-icon
          style="cursor:pointer;margin-right: 10px"
          type="left"
        />设置微信推送
      </div>
      <div>
        <div
          @click="changeToShowPage(2)"
          class="share-top-user"
          v-if="true"
        ></div>
      </div>
    </div>
    <div class="divider"></div>
    <div style="height:412px">
      <div style="height:380px" class="users-outer">
        <div
          class="history-item"
          v-for="(item, index) in historyList"
          :key="index"
        >
          <div class="history-status" v-if="item.status == 'succeeded'">
            <div class="history-status-light"></div>
            <div>成功 - {{ item.updateTime }}</div>
          </div>
          <div class="history-status" v-else-if="item.status == 'failed'">
            <div class="history-status-light history-status-fail"></div>
            <div>失败 - {{ item.updateTime }}</div>
          </div>
          <div class="history-push-users">
            <span v-if="item.detail.fail == 0">
              推送{{ item.detail.success }}个用户</span
            >
            <span v-else>
              成功{{ item.detail.success }}人，失败{{
                item.detail.fail
              }}人</span
            >
            <a-icon type="right" />
          </div>
        </div>
        <a-empty
          v-if="!historyList.length"
          :image="IMAGE_URL"
          :image-style="{ height: '200px' }"
          description="暂无数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/dashboard";
import IMAGE_URL from "@/assets/images/ai_no_task.svg";
import dayjs from "dayjs";
export default {
  components: {},
  props: {
    dashboardId: String,
    qrcodeId: String
  },
  data() {
    return {
      IMAGE_URL,
      historyList: [],
      sortText: "按照时间降序"
    };
  },
  computed: {},
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      const { data } = await API.getHistoryData(
        this.dashboardId,
        this.qrcodeId
      );
      this.historyList = this.sortHistory(data);
    },
    changeToShowPage(index) {
      this.$emit("changeShowPage", index);
    },
    sortHistory(list) {
      return list.sort((a, b) => {
        return dayjs(b.createTime).unix() - dayjs(a.createTime).unix();
      });
    }
  }
};
</script>
<style lang="less" scoped>
.share-top {
  display: flex;
  padding: 0 16px 0;
  border-bottom: 1px solid #c9cdd4;
}
.share-top-title {
  font-weight: 600;
  font-size: 18px;
  height: 36px;
}
.share-top-user {
  display: flex;
  cursor: pointer;
}

.divider {
  height: 0;
}
.users-outer {
  padding-top: 18px;
  height: 230px;
  overflow: scroll;
}
.users-delete {
  float: right;
  width: 100px;
  font-size: 14px;
  color: #6aa1ff;
  cursor: pointer;
}
.history-item {
  display: flex;
  justify-content: space-between;
  height: 34px;
  width: 480px;
  margin: 0px auto 10px;
  border-radius: 8px;
  line-height: 34px;
  background: #f7f8fa;
}
.history-status {
  display: flex;
}
.history-status-light {
  width: 12px;
  height: 12px;
  margin: 12px 27px 12px 21px;
  color: #000000;
  border-radius: 6px;
  background: #23c343;
}
.history-status-fail {
  background: #f9cc45;
}
.history-push-users {
  font-size: 12px;
  width: 120px;
  margin-right: 10px;
  text-align: right;
  color: #86909c;
}
.history-sort {
  margin: 23px 0 10px;
  text-align: center;
  color: #6aa1ff;
  cursor: pointer;
}
</style>
