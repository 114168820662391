import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/dashboard") });

interceptor.auth(axios);

export function getDashboard(dashboardId) {
  return axios.get(dashboardId);
}

export function saveDashboard(data) {
  return axios.post("save", data);
}

/**
 * 获取表格中的字段列表，结构和 websocket 中返回的 columns 一致
 * @param {{ datasheetId: string }} params
 */
export function getDatasheetColumns(params) {
  return axios.get("../datasheet/worksheet/columns", { params });
}

export function getDistinctValue(data) {
  return axios.post("distinctValue", data);
}

export function getWidgetData(data) {
  return axios.post("chart/data", data);
}

export function shareDashboard(data) {
  return axios.post(`${data.dashboardId}/shared`, data);
}

export function unshareDashboard(data) {
  return axios.post(`${data.dashboardId}/shared/${data.sharedId}/cancel`, data);
}

// 获取看板分享配置
export function getDashboardSharedInfo(dashboardId) {
  return axios.get(`${dashboardId}/shared`);
}

//获取推送微信二维码
export function getQrcodeData(dashboardId) {
  return axios.get(`${dashboardId}/wxPush/config`);
}

//获取推送微信二维码
export function postQrcodeData(dashboardId, params) {
  return axios.post(`${dashboardId}/wxPush/config`, params);
}

//获取推送微信二维码关注用户
export function getQrcodeUsersData(dashboardId) {
  return axios.get(`${dashboardId}/wxPush/subscribers`);
}

//获取推送历史记录
export function getHistoryData(dashboardId, jobId) {
  return axios.get(`${dashboardId}/wxPush/${jobId}/logs`);
}

//移除推送用户
export function deleteUser(dashboardId, id) {
  return axios.post(`${dashboardId}/wxPush/subscriber/${id}/remove`);
}
