<template>
  <div style="height:450px;overflow: hidden;">
    <div class="divider"></div>
    <div style="padding:10px 0 0 56px;">
      <a-form-item :label="'推送群名称'" required>
        <a-input
          v-model="groupName"
          style="width: 457px;background: #F2F3F5;border-radius: 4px;height: 38px;"
        />
      </a-form-item>
      <a-form-item :label="'webhook'" required>
        <a-input
          v-model="webhookName"
          style="width: 457px;background: #F2F3F5;border-radius: 4px;height: 38px;"
        />
        <div style="color:#C9CDD4;line-height:12px;font-size:12px">
          &nbsp;可在群聊机器人中获得
        </div>
      </a-form-item>
      <a-form-item :label="'推送频率'" required>
        <div @click="switchFlag = !switchFlag" class="switch-btn">
          <div :class="{ 'switch-select': switchFlag }" style="width:80px">
            定时推送
          </div>
          <div :class="{ 'switch-select': !switchFlag }" style="width:122px">
            数据更新时推送
          </div>
        </div>
        <div v-if="switchFlag">
          <a-select
            defaultValue="days"
            style="width: 82px;height: 28px;"
            v-model="frequencyType"
          >
            <a-select-option
              v-for="(item, index) in frequencyArray"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- <a-select
            defaultValue="monday"
            v-if="frequencyType == 'weeks'"
            style="width: 82px;height: 28px;margin-left:20px;border-radius: 4px;"
            v-model="weeksValue"
          >
            <a-select-option
              v-for="(item, index) in weeksArray"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
            defaultValue="1"
            v-if="frequencyType == 'months'"
            style="width: 82px;height: 28px;margin-left:20px;border-radius: 4px;"
            v-model="monthsValue"
          >
            <a-select-option
              v-for="(item, index) in monthsArray"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select> -->
          <a-date-picker
            style="margin-left:20px;border-radius: 4px;"
            :default-value="time"
            :allowClear="false"
            :locale="locale"
            show-time
            :showToday="false"
            format="YYYY-MM-DD HH:mm:ss"
            v-model="time"
          />
        </div>
        <div v-else>
          <div style="margin-bottom:18px" class="title-class">连接器</div>
          <a-select
            placeholder="请选择"
            @change="sourceChange"
            :dropdownMatchSelectWidth="false"
            style="width: 178px;height: 28px;"
            v-model="sourceValue"
          >
            <a-select-option
              v-for="(item, index) in sourceList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <div v-if="sourceValue" style="margin:18px 0" class="title-class">
            推送任务
          </div>
          <a-select
            v-if="sourceValue"
            @change="destinationChange"
            placeholder="请选择"
            :dropdownMatchSelectWidth="false"
            style="width: 178px;height: 28px;"
            v-model="destinationValue"
          >
            <a-select-option
              v-for="(item, index) in destination"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-form-item>
    </div>
    <div class="footer">
      <a-button @click="goback" style="width:64px;height:32px">返回</a-button>
      <a-button
        style="width:64px;height:32px;margin-left:21px"
        type="primary"
        :disabled="submitDisable"
        @click="submit"
      >
        确定
      </a-button>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/share-ground";
import dayjs from "dayjs";
import { frequencyArray, weeksArray, monthsArray } from "./columns-share";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import workspace from "@/views/datamodel/models/workspace";
export default {
  components: {},
  props: {
    paramsData: Object,
    dashboardId: String
  },
  data() {
    return {
      groupName: "",
      webhookName: "",
      switchFlag: true,
      frequencyType: "days",
      locale,
      weeksValue: "monday",
      monthsValue: "1",
      time: dayjs(),
      sourceList: [],
      sourceValue: undefined,
      destinationList: [],
      destination: [],
      connectionList: [],
      destinationValue: undefined,
      targetId: "",
      frequencyArray: frequencyArray,
      weeksArray: weeksArray,
      monthsArray: monthsArray,
      dashboardData: {},
      datasheetId: workspace.currentDatasheetId,
      msgBodyContent: [],
      shareUrl: ""
    };
  },
  created() {
    this.getDashboard();
    this.getConnectorList();
    this.getShareUrl();
    this.$emit("changeTitle", "群推送设置");
  },
  methods: {
    goback(type) {
      this.$emit("goback", type);
    },
    updateData() {
      if (this.paramsData) {
        this.groupName = this.paramsData.name;
        this.webhookName = this.paramsData.appConfig.webhook;
        this.dashboardData.name = this.paramsData.msgBody.title;
        this.msgBodyContent = this.paramsData.msgBody.content.split("\n");
        if (this.paramsData.connectionId) {
          this.switchFlag = false;
          this.targetId = this.paramsData.connectionId;
          this.connectionList.forEach(item => {
            if (item.id == this.paramsData.connectionId) {
              this.sourceValue = item.sourceId;
              this.getDestinationList(this.sourceValue);
              this.destinationValue = item.destinationId;
            }
          });
        } else {
          this.time = dayjs(this.paramsData.schedule.startTime);
          this.frequencyType = this.paramsData.schedule.timeUnit;
        }
      }
    },
    async submit() {
      let schedule = {
        startTime: dayjs(this.time).format("YYYY-MM-DD HH:mm:ss"),
        timeUnit: this.frequencyType,
        units: 1
      };

      const params = {
        id: this.paramsData ? this.paramsData.id : "",
        name: this.groupName,
        dashboardId: this.dashboardId,
        pushType: "message",
        appType: "wechat-group",
        appConfig: {
          webhook: this.webhookName
        },
        schedule: this.switchFlag ? schedule : null,
        connectionId: this.switchFlag ? "" : this.targetId,
        pushUnits: [],
        pushConditions: {
          expression: ""
        },
        msgType: "news",
        msgBody: {
          title: this.dashboardData.name,
          titleColor: null,
          titleFontSize: null,
          cover: "",
          url: this.shareUrl,
          content: this.msgBodyContent.join("\\n")
        }
      };
      await API.saveSchedule(params);
      if (this.paramsData) {
        this.goback("update");
      } else {
        this.goback("submit");
      }
    },
    async getConnectorList() {
      this.sourceList = [];
      this.destinationList = [];
      const { data } = await API.getConnectorList();
      data.forEach(item => {
        if (item.category == "source") {
          this.sourceList.push(item);
        } else {
          this.destinationList.push(item);
        }
      });
      this.getConnectionList();
    },
    async getConnectionList() {
      const { data } = await API.getConnectionList();
      data.forEach(item => {
        this.connectionList.push(item);
      });
      this.updateData();
    },
    async getDashboard() {
      const { data } = await API.getDashboard(this.datasheetId);
      data.layout.forEach(item => {
        if (item.type == "kpi") {
          this.msgBodyContent.push(
            item.options.title + ":{" + item.title + "}"
          );
        }
      });
      this.dashboardData = data;
    },
    getDestinationList(id) {
      this.destination = [];
      this.connectionList.forEach(item => {
        if (item.sourceId == id) {
          this.destinationList.forEach(items => {
            if (item.destinationId == items.id) {
              this.destination.push(items);
            }
          });
        }
      });
    },
    sourceChange() {
      this.destination = [];
      this.targetId = "";
      this.destinationValue = undefined;
      this.getDestinationList(this.sourceValue);
    },
    destinationChange() {
      this.connectionList.forEach(item => {
        if (
          item.destinationId == this.destinationValue &&
          item.sourceId == this.sourceValue
        ) {
          this.targetId = item.id;
        }
      });
    },
    async getShareUrl() {
      const { data } = await API.getShareUrl(this.dashboardId);
      // this.shareUrl = "https://test.nexadata.cn/share/dashboard/" + data[0].id;
      this.shareUrl = window.location.host + "/share/dashboard/" + data[0].id;
    }
  },
  computed: {
    submitDisable() {
      if (this.switchFlag) {
        return !this.groupName || !this.webhookName;
      }
      return !this.groupName || !this.webhookName || !this.targetId;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 4px;
}
/deep/.ant-form-item-control {
  line-height: 14px;
  margin-bottom: 15px;
  color: #000000;
}
/deep/ .ant-form-item-control {
  margin-bottom: 0px;
}
.divider {
  height: 0;
  border-bottom: 1px solid #c9cdd4;
}
.switch-btn {
  display: flex;
  width: 206px;
  height: 34px;
  padding: 2px;
  margin-bottom: 20px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 30px;
  background: #f2f3f5;
  color: #4e5969;
  div {
    text-align: center;
    cursor: pointer;
  }
  .switch-select {
    background: #ffffff;
    border-radius: 2px;
    color: #165dff;
  }
  .title-class {
    font-size: 14px;
    line-height: 14px !important;
  }
}
.footer {
  position: absolute;
  right: 20px;
  bottom: 16px;
}
</style>
