function intersect(widget1, widget2) {
  return !(
    widget1.x >= widget2.x + widget2.w ||
    widget1.x + widget1.w <= widget2.x ||
    widget1.y >= widget2.y + widget2.h ||
    widget1.y + widget1.h <= widget2.y
  );
}

/**
 * 简单粗暴地计算新组件位置。
 * 效率低了点，但以毫秒为单位就忽略罢 😅 摆烂
 * @param {import("../widgets/type").IWidget} widget
 * @param {import("../widgets/type").IWidget[]} layout
 */
export default function(widget, layout) {
  layout = layout.map(item => ({ x: item.x, y: item.y, w: item.w, h: item.h }));
  const MAX_W = 24;
  const { w = 1, h = 1 } = widget || {};
  let x = 0;
  let y = 0;
  while (layout.some(item => intersect(item, { x, y, w, h }))) {
    x += 1;
    if (x + w > MAX_W) {
      x = 0;
      y += 1;
    }
  }
  return { x, y };
}
