<template>
  <a-modal width="540px" :footer="null" centered>
    <ShareQRCode
      @changeShowPage="changeShowPage"
      @getQrcodeId="getQrcodeId"
      :dashboardId="dashboardId"
      v-if="showShareModel == 1"
    ></ShareQRCode>
    <ShareHistory
      @changeShowPage="changeShowPage"
      :qrcodeId="qrcodeId"
      :dashboardId="dashboardId"
      v-if="showShareModel == 2"
    ></ShareHistory>
    <ShareUsers
      @changeShowPage="changeShowPage"
      :dashboardId="dashboardId"
      v-if="showShareModel == 3"
    ></ShareUsers>
  </a-modal>
</template>

<script>
import ShareQRCode from "./ShareQRCode.vue";
import ShareHistory from "./ShareHistory.vue";
import ShareUsers from "./ShareUsers.vue";

export default {
  components: { ShareQRCode, ShareHistory, ShareUsers },
  props: {
    dashboardId: String
  },
  data() {
    return {
      showShareModel: 1,
      qrcodeId: ""
    };
  },
  created() {},
  methods: {
    changeShowPage(index) {
      this.showShareModel = index;
    },
    getQrcodeId(id) {
      this.qrcodeId = id;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-close-x {
  margin: 15px 5px 0 0;
  height: 46px;
  width: 46px;
  line-height: 46px;
}
</style>
