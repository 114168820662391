var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"box-shadow":"1px 1px 6px 1px rgba(0, 0, 0, 0.1)","border-radius":"10px","width":"500px","margin":"20px auto"}},[_c('div',{staticClass:"history-item-class",on:{"click":function($event){_vm.showLogs = !_vm.showLogs}}},[_c('div',{staticClass:"history-left-item-class"},[_c('div',{staticStyle:{"padding":"20px 10px 0 10px"}},[_c('svg',{attrs:{"viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":20,"height":20}},[_c('path',{attrs:{"d":"M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z","fill":_vm.historyItem.attempts[0].status == 'failed'
                ? '#ed3f14'
                : _vm.historyItem.attempts[0].status == 'succeeded'
                ? '#19be6b'
                : '#1890ff',"fill-opacity":".25"}}),_c('path',{attrs:{"d":"M512 512m-398.222222 0a398.222222 398.222222 0 1 0 796.444444 0 398.222222 398.222222 0 1 0-796.444444 0Z","fill":_vm.historyItem.attempts[0].status == 'failed'
                ? '#ed3f14'
                : _vm.historyItem.attempts[0].status == 'succeeded'
                ? '#19be6b'
                : '#1890ff'}}),_c('path',{attrs:{"d":"M483.555556 483.555556m-312.888889 0a312.888889 312.888889 0 1 0 625.777777 0 312.888889 312.888889 0 1 0-625.777777 0Z","fill":"#F1F6F8","opacity":".3"}}),_c('path',{attrs:{"d":"M625.777778 455.111111m-56.888889 0a56.888889 56.888889 0 1 0 113.777778 0 56.888889 56.888889 0 1 0-113.777778 0Z","fill":"#F1F6F8","opacity":".5"}})])]),_c('div',[_c('div',{staticClass:"stutas-text"},[_c('span',[(_vm.historyItem.attempts[0].status == 'succeeded')?_c('span',[_vm._v("成功")]):(_vm.historyItem.attempts[0].status == 'running')?_c('span',[_vm._v(" 运行中 ")]):_c('span',[_vm._v("失败")]),_vm._v(" - "+_vm._s(_vm.historyItem.attempts[0].endedAt))])]),_c('div',{staticClass:"stutas-params-text"},[_c('span',[_c('span',[_vm._v("耗时"+_vm._s(_vm.useTime))])]),(_vm.historyItem.attempts[0].bytesSynced)?_c('span',[_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',[_vm._v(_vm._s(_vm.historyItem.attempts[0].bytesSynced))])],1):_vm._e(),_c('span',[_c('a-divider',{attrs:{"type":"vertical"}}),(_vm.historyItem.attempts[0].configType == 'reset_connection')?_c('span',[_vm._v("重置")]):_c('span',[_vm._v("同步")])],1)])])]),_c('div',[_c('a-icon',{staticStyle:{"color":"#c9cdd4","font-size":"20px","margin":"20px 20px 0 0"},attrs:{"type":!_vm.showLogs ? 'right' : 'down'}})],1)]),(_vm.showLogs)?_c('Terminal',{attrs:{"record":_vm.historyItem,"get-job-log":_vm.getTaskLog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }