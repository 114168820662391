<template>
  <div class="users">
    <div class="share-top">
      <div @click="changeToShowPage(1)" class="share-top-title">
        <a-icon
          style="cursor:pointer;margin-right: 10px;"
          type="left"
        />设置微信推送
      </div>
      <div>
        <div
          @click="schangeToShowPage(2)"
          class="share-top-user"
          v-if="true"
        ></div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="content-outer">
      <div>
        共有
        <span style="font-size:20px;color: #6AA1FF">{{ userList.length }}</span>
        位推送用户
      </div>
      <div class="users-outer">
        <div v-for="(item, index) in userList" :key="index">
          <div style="margin-top:14px">
            <img :src="item.detail.headImgUrl" />
            <span class="users-name">{{ item.detail.nickname }}</span>
            <span
              @click="clickDeleteUser(item.id, item.detail.nickname)"
              v-if="frequencyFlag"
              class="users-delete"
              >移除推送</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/dashboard";
import store from "@/store";

export default {
  components: {},
  props: {
    dashboardId: String
  },
  data() {
    return {
      frequencyFlag: false,
      userList: [],
      dateNow: null,
      deletId: ""
    };
  },
  created() {
    this.frequencyFlag = store.isSuperAdmin;
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const { data } = await API.getQrcodeUsersData(this.dashboardId);
      this.userList = data;
    },
    clickDeleteUser(id, name) {
      let that = this;
      this.deletId = id;
      let content = `确定要移除用户${name}吗？`;
      this.$confirm({
        title: "提示",
        content: () => <div style="color:red;">{content}</div>,
        onOk() {
          that.deleteUser();
        }
      });
    },
    async deleteUser() {
      await API.deleteUser(this.dashboardId, this.deletId);
      this.$message.success("移除成功");
      this.getUserList();
    },
    changeToShowPage(index) {
      this.$emit("changeShowPage", index);
    }
  }
};
</script>
<style lang="less" scoped>
.content-outer {
  height: 412px;
  padding: 24px 33px 0;
  line-height: 20px;
}
.users-outer {
  padding-top: 18px;
  height: 350px;
  overflow: scroll;
}
.users-outer img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border-radius: 15px;
}
.users-name {
  color: #000000;
  margin-left: 15px;
  font-size: 14px;
}
.divider {
  height: 0;
}
.users-delete {
  float: right;
  font-size: 14px;
  line-height: 30px;
  color: #6aa1ff;
  cursor: pointer;
}
.share-top {
  display: flex;
  padding: 0 16px 0;
  border-bottom: 1px solid #c9cdd4;
}
.share-top-title {
  font-weight: 600;
  font-size: 18px;
  height: 36px;
}
.share-top-user {
  display: flex;
  cursor: pointer;
}
.share-top-user-one {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  border-radius: 14px;
  font-size: 12px;
  overflow: hidden;
}
</style>
