<template>
  <div style="height:450px;overflow: hidden;">
    <div v-if="showList">
      <div class="divider"></div>
      <div @click="showList = false" class="add-task-btn">新增群推送</div>
      <div v-if="taskListStutasData.length" class="task-list">
        <TaskItem
          @changeTitle="changeTitle"
          @showChange="showChange"
          @deleteItem="deleteItem"
          @runDatasheetApp="runDatasheetApp"
          v-for="(item, index) in taskListData"
          :key="index"
          :taskItem="item"
          :taskListStutasItem="taskListStutasData[index]"
        ></TaskItem>
      </div>
    </div>

    <BuildTasks
      @goback="goback"
      :haveList="haveList"
      :dashboardId="dashboardId"
      @changeTitle="changeTitle"
      v-else
    />
  </div>
</template>

<script>
import TaskItem from "./components/TaskItem.vue";
import BuildTasks from "./BuildTasks.vue";
export default {
  components: { TaskItem, BuildTasks },
  props: {
    dashboardId: String,
    taskListData: Array,
    taskListStutasData: Array
  },
  data() {
    return { showList: true, haveList: true };
  },
  created() {
    this.$emit("changeTitle", "当前看板群推送");
  },
  methods: {
    goback(type) {
      this.showList = true;
      this.$emit("goback", type);
    },
    changeTitle(title) {
      this.$emit("changeTitle", title);
    },
    showChange(params) {
      this.$emit("showChange", params);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    runDatasheetApp(item) {
      this.$emit("runDatasheetApp", item);
    }
  }
};
</script>
<style lang="less" scoped>
.add-task-btn {
  display: inline-block;
  margin: 27px 0 0 50px;
  padding: 5px 16px;
  background: #4080ff;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.task-list {
  overflow: scroll;
  height: 300px;
  margin: 40px auto 10px;
  padding-top: 20px;
}
.divider {
  height: 0;
  border-bottom: 1px solid #c9cdd4;
}
</style>
