<template>
  <NodeHeader :node-id="$route.params.datasheetId">
    <Focusable slot="actions" size="large" @click="addTextWidget()">
      <i class="ndl-icon-font-size1"></i>
      <span>添加文本</span>
    </Focusable>
    <Focusable slot="actions" size="large" @click="addWidget()">
      <i class="ndl-icon-pie-chart"></i>
      <span>添加图表</span>
    </Focusable>
    <a-divider slot="actions" type="vertical" />
    <Focusable slot="actions" size="large" @click="share()">
      <i class="ndl-icon-share-2"></i>
      <span>分享</span>
    </Focusable>
    <Focusable slot="actions" size="large" @click="shareToWx()">
      <img class="app-icon" src="images/app_share_wx.svg" />
      <span style="color:#1AC026">微信推送</span>
    </Focusable>
    <Focusable slot="actions" size="large" @click="shareToGroupChat()">
      <img class="app-icon" src="images/app_group_chat.svg" />
      <span style="color:#6AA1FF">群推送</span>
    </Focusable>
  </NodeHeader>
</template>

<script>
import { NodeHeader } from "@/views/datamodel/components/workspace-header";
import Focusable from "@/views/datamodel/components/focusable";
import ModelWidget from "./models/widget";
import shareDashboard from "./share";
import shareDashboardToWx from "./share-wx";
import shareDashboardToGroupChat from "./share-group-chat";
import * as Widgets from "./widgets";
import * as utils from "./utils";

export default {
  components: { Focusable, NodeHeader },
  props: {
    layout: Array
  },
  methods: {
    addTextWidget() {
      this.addWidget(Widgets.Text.type);
    },
    async addWidget(type = Widgets.LineBasic.type) {
      const widget = new ModelWidget({ type });
      await utils.configWidget(widget);
      // 切换图表类型后，需要修正默认的图表尺寸
      const WidgetDescriber = Widgets.getWidget(widget.type);
      widget.title = WidgetDescriber.name;
      if (WidgetDescriber.size) {
        Object.assign(widget, WidgetDescriber.size);
      }
      // 而后再计算组件放置位置
      const pos = utils.autoLayout(widget, this.layout || []);
      widget.x = pos.x;
      widget.y = pos.y;
      this.layout.push(widget);
    },
    share() {
      shareDashboard(this.$route.params.datasheetId);
    },
    shareToWx() {
      shareDashboardToWx(this.$route.params.datasheetId);
    },
    shareToGroupChat() {
      shareDashboardToGroupChat(this.$route.params.datasheetId);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.header .focusable {
  width: auto;
  padding: 0 8px;
  margin: 0 8px;
  font-size: 14px;
  span {
    margin-left: 6px;
  }
}
</style>
