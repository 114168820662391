<template>
  <a-modal
    width="80vw"
    centered
    :footer="null"
    :mask-closable="false"
    :closable="false"
    :keyboard="false"
  >
    <PaneHeader :widget="widget" @cancel="$reject" @confirm="onConfirm" />
    <div class="pane-wrapper">
      <PaneMain :widget="widget" />
      <PaneSide :widget="widget" />
    </div>
  </a-modal>
</template>

<script>
import PaneHeader from "./PaneHeader";
import PaneMain from "./PaneMain";
import PaneSide from "./PaneSide";
import Widget from "../models/widget";

export default {
  components: { PaneHeader, PaneMain, PaneSide },
  props: {
    defaultWidget: Object,
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      widget: Widget.from(this.defaultWidget)
    };
  },
  methods: {
    onConfirm() {
      Object.assign(this.defaultWidget, this.widget);
      this.$resolve(this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.ant-modal-root {
  .overwrite_modal();
  /deep/ .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .pane-wrapper {
    flex: 1 1 0;
    height: 0;
    display: flex;
  }
}
</style>
