<template>
  <div
    style="box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);border-radius: 10px;width: 500px; margin: 20px auto;"
  >
    <div class="history-item-class" @click="showLogs = !showLogs">
      <div class="history-left-item-class">
        <div style="padding: 20px 10px 0 10px">
          <svg
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            :width="20"
            :height="20"
          >
            <path
              d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
              :fill="
                historyItem.attempts[0].status == 'failed'
                  ? '#ed3f14'
                  : historyItem.attempts[0].status == 'succeeded'
                  ? '#19be6b'
                  : '#1890ff'
              "
              fill-opacity=".25"
            />
            <path
              d="M512 512m-398.222222 0a398.222222 398.222222 0 1 0 796.444444 0 398.222222 398.222222 0 1 0-796.444444 0Z"
              :fill="
                historyItem.attempts[0].status == 'failed'
                  ? '#ed3f14'
                  : historyItem.attempts[0].status == 'succeeded'
                  ? '#19be6b'
                  : '#1890ff'
              "
            />
            <path
              d="M483.555556 483.555556m-312.888889 0a312.888889 312.888889 0 1 0 625.777777 0 312.888889 312.888889 0 1 0-625.777777 0Z"
              fill="#F1F6F8"
              opacity=".3"
            />
            <path
              d="M625.777778 455.111111m-56.888889 0a56.888889 56.888889 0 1 0 113.777778 0 56.888889 56.888889 0 1 0-113.777778 0Z"
              fill="#F1F6F8"
              opacity=".5"
            />
          </svg>
        </div>
        <div>
          <div class="stutas-text">
            <span
              ><span v-if="historyItem.attempts[0].status == 'succeeded'"
                >成功</span
              >
              <span v-else-if="historyItem.attempts[0].status == 'running'">
                运行中
              </span>
              <span v-else>失败</span> -
              {{ historyItem.attempts[0].endedAt }}</span
            >
          </div>
          <div class="stutas-params-text">
            <span>
              <span>耗时{{ useTime }}</span>
            </span>
            <span v-if="historyItem.attempts[0].bytesSynced">
              <a-divider type="vertical" />
              <span>{{ historyItem.attempts[0].bytesSynced }}</span>
            </span>
            <!-- <span>
              <a-divider type="vertical" />
              <span
                >{{ historyItem.attempts[0].recordsSynced || 0 }}条记录</span
              >
            </span> -->
            <span>
              <a-divider type="vertical" />
              <span
                v-if="historyItem.attempts[0].configType == 'reset_connection'"
                >重置</span
              >
              <span v-else>同步</span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <a-icon
          style="color:#c9cdd4;font-size: 20px;margin:20px 20px 0 0"
          :type="!showLogs ? 'right' : 'down'"
        />
      </div>
    </div>

    <Terminal v-if="showLogs" :record="historyItem" :get-job-log="getTaskLog" />
  </div>
</template>

<script>
import * as API from "@/api/share-ground";
import Terminal from "@/views/connections/components/job-terminal";
import { timeDiff } from "@/views/connections/utils/helpers";
export default {
  components: { Terminal },
  props: {
    historyItem: Object
  },
  data() {
    return {
      showLogs: false,
      logsData: []
    };
  },
  created() {},
  computed: {
    useTime() {
      return timeDiff(
        this.historyItem.attempts[0].createdAt,
        this.historyItem.attempts[0].endedAt
      );
    }
  },
  methods: {
    async getTaskLog(job) {
      const { data } = await API.getTaskLog(job.id);
      // 优化显示后端返回的 json 字符串中的换行符
      const attempts = (data && data.attempts) || [];
      for (const item of attempts) {
        const logs = item.logs || {};
        logs.logLines = logs.logLines.reduce(
          (result, line) => result.concat(line.split("\n")),
          []
        );
      }
      return data;
    }
  }
};
</script>
<style lang="less" scoped>
.history-item-class {
  display: flex;
  justify-content: space-between;
  height: 60px;
  border-radius: 20px;
}
/deep/.ant-divider {
  background: #c9cdd4;
}
.status-light {
  width: 16px;
  height: 16px;
  margin: 21px 27px 41px 21px;
  color: #000000;
  border-radius: 8px;
  background: #23c343;
}
.history-left-item-class {
  display: flex;
  justify-content: space-between;
}
.stutas-text {
  margin-top: 6px;
  font-size: 16px;
  color: #000000;
}
.stutas-params-text {
  margin-top: 2px;
  color: #c9cdd4;
}
.fail-light {
  background: rgb(237, 63, 20);
}
.logs-class {
  min-height: 300px;
  background-color: rgb(253, 253, 253);
}
.rotate90 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
</style>
