<template>
  <div class="share-footer">
    <div
      @click="changeToShowPage(2)"
      style="margin-left:400px;cursor: pointer;"
    >
      <a-icon height="17px" width="17px" type="history" />
      <span style="margin-left:7px">推送历史</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dashboardId: String
  },
  data() {
    return {};
  },
  methods: {
    changeToShowPage(index) {
      this.$emit("changeShowPage", index);
    }
  }
};
</script>
<style lang="less" scoped>
.share-footer {
  position: absolute;
  height: 35px;
  bottom: 15px;
  font-size: 14px;
  line-height: 35px;
}
</style>
