<template>
  <div class="share-content">
    <div @click="switchFlag = !switchFlag" class="switch-btn">
      <div :class="{ 'switch-select': switchFlag }" style="width:80px">
        定时推送
      </div>
      <div :class="{ 'switch-select': !switchFlag }" style="width:122px">
        数据更新时推送
      </div>
    </div>
    <div style="height:80px">
      <div v-if="switchFlag" class="share-content-select">
        <div>
          <span>推送频率</span>
          <a-select
            style="width: 80px;margin-left: 10px;"
            v-model="formdata.frequency"
            @change="changePushParams('频率')"
            :disabled="!frequencyFlag"
          >
            <a-select-option
              v-for="item in frequencyOption"
              :key="item.key"
              :value="item.value"
            >
              {{ item.key }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <span>推送时间</span>
          <a-date-picker
            v-model="dateNow"
            :default-value="dateNow"
            show-time
            :allowClear="false"
            :locale="locale"
            :showToday="false"
            format="YYYY-MM-DD HH:mm:ss"
            :placeholder="'开始时间'"
            @change="changeConfig"
            style="width: 160px;margin-left:10px"
            @ok="changePushParams('时间')"
          />
        </div>
      </div>
      <div v-else>
        <div style="display:flex">
          <div>
            <div style="margin-bottom:18px" class="title-class">连接器</div>
            <a-select
              placeholder="请选择"
              @change="sourceChange"
              :dropdownMatchSelectWidth="false"
              style="width: 188px;height: 28px;"
              v-model="sourceValue"
            >
              <a-select-option
                v-for="(item, index) in sourceList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div style="margin-left:60px">
            <div
              v-if="sourceValue"
              style="margin:0 0 18px 0"
              class="title-class"
            >
              推送任务
            </div>
            <a-select
              v-if="sourceValue"
              @change="destinationChange"
              placeholder="请选择"
              :dropdownMatchSelectWidth="false"
              style="width: 188px;height: 28px;"
              v-model="destinationValue"
            >
              <a-select-option
                v-for="(item, index) in destination"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <div class="qrcodeOuter" style="overflow:hidden" v-if="qrcodeLoading">
      <div class="loading"></div>
    </div>
    <div v-else class="qrcode">
      <img :src="qrdata.wxMpSubscribeQrCodeUrl" alt="" />
    </div>

    <div class="qr-text">
      <div class="qr-text-content">
        <div>关注「下秒数据」服务号</div>
        <div>接收本看板定时推送</div>
      </div>
      <div class="qr-text-divider"></div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/dashboard";
import * as Share from "@/api/share-ground";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import dayjs from "dayjs";
import store from "@/store";

export default {
  props: {
    dashboardId: String
  },
  data() {
    return {
      openTimePicker: false,
      qrcodeLoading: false,
      frequencyFlag: false,
      switchFlag: true,
      locale,
      dateNow: null,
      formdata: {
        frequency: "",
        pushTime: ""
      },
      frequencyOption: [
        { key: "每日", value: "days" },
        { key: "每周", value: "weeks" },
        { key: "每月", value: "months" }
      ],
      frequency: "days",
      loading: true,
      qrdata: {},
      defaultTime: "",
      destination: [],
      targetId: "",
      sourceList: [],
      connectionList: [],
      destinationValue: undefined,
      sourceValue: undefined
    };
  },
  created() {
    this.frequencyFlag = store.isSuperAdmin;
    this.getQrcode();
    this.getConnectorList();
  },
  methods: {
    async getQrcode() {
      this.qrcodeLoading = true;
      const { data } = await API.getQrcodeData(this.dashboardId);
      this.qrdata = data;
      // this.qrdata.connectionId = '929da87f-dfe0-4962-9e81-955ad6e9c0a1'
      this.$emit("getQrcodeId", this.qrdata.id);
      this.formdata.frequency = data?.schedule?.timeUnit || "days";
      this.defaultTime = data?.schedule?.startTime || dayjs();
      if (!data?.schedule?.timeUnit && !data.connectionId) {
        this.changePushParams();
      }
      this.dateNow = dayjs(this.defaultTime);
      this.qrcodeLoading = false;
    },
    changeConfig(time) {
      this.formdata.pushTime = dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    async changePushParams(str) {
      this.openTimePicker = false;
      let obj = Object.assign(this.qrdata, {});
      if (str == "连接器") {
        obj.schedule = {};
        obj.connectionId = this.targetId;
      } else {
        obj.connectionId = "";
        if (!obj.schedule) {
          obj.schedule = {};
        }
        (obj.schedule.startTime =
          this.formdata.pushTime ||
          dayjs(this.defaultTime).format("YYYY-MM-DD HH:mm:ss")),
          (obj.schedule.timeUnit = this.formdata.frequency);
        obj.schedule.units = 1;
      }
      await API.postQrcodeData(this.dashboardId, obj);
      if (str) {
        if (str == "连接器") {
          this.$message.success(`成功修改推送推送任务`);
        } else {
          this.$message.success(`成功修改推送${str}`);
        }
      }
    },
    changeToShowPage(index) {
      this.$emit("changeShowPage", index);
    },
    sourceChange() {
      this.destination = [];
      this.targetId = "";
      this.destinationValue = undefined;
      this.getDestinationList(this.sourceValue);
    },
    destinationChange() {
      this.connectionList.forEach(item => {
        if (
          item.destinationId == this.destinationValue &&
          item.sourceId == this.sourceValue
        ) {
          this.targetId = item.id;
          this.changePushParams("连接器");
        }
      });
    },
    getDestinationList(id) {
      this.destination = [];
      this.connectionList.forEach(item => {
        if (item.sourceId == id) {
          this.destinationList.forEach(items => {
            if (item.destinationId == items.id) {
              this.destination.push(items);
            }
          });
        }
      });
    },
    async getConnectorList() {
      this.sourceList = [];
      this.destinationList = [];
      const { data } = await Share.getConnectorList();
      data.forEach(item => {
        if (item.category == "source") {
          this.sourceList.push(item);
        } else {
          this.destinationList.push(item);
        }
      });
      this.getConnectionList();
    },
    async getConnectionList() {
      const { data } = await Share.getConnectionList();
      data.forEach(item => {
        this.connectionList.push(item);
      });
      this.updateData();
    },
    updateData() {
      if (this.qrdata.connectionId) {
        this.switchFlag = false;
        this.targetId = this.qrdata.connectionId;
        this.connectionList.forEach(item => {
          if (item.id == this.qrdata.connectionId) {
            this.sourceValue = item.sourceId;
            this.getDestinationList(this.sourceValue);
            this.destinationValue = item.destinationId;
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.share-top {
  display: flex;
  height: 46px;
  padding: 0 16px 0;
  border-bottom: 1px solid #b8b8b8;
}
.share-top-title {
  font-size: 16px;
  line-height: 46px;
}
.share-top-user {
  display: flex;
  cursor: pointer;
}
.share-top-user-one {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  border-radius: 14px;
  font-size: 12px;
  overflow: hidden;
}
.share-top-user-one img {
  width: 100%;
  height: 100%;
}
.share-more-num {
  text-align: center;
  line-height: 28px;
}
.divider {
  height: 0;
}
.share-content {
  height: 412px;
  padding: 28px 16px 0px;
}
.share-content-select {
  display: flex;
  justify-content: space-around;
}
.qrcode {
  width: 140px;
  height: 140px;
  margin: 24px auto 0;
}
.qrcode img {
  height: 100%;
  width: 100%;
}
.qrcodeOuter {
  width: 140px;
  height: 140px;
  margin: 24px auto 0;
  background-color: #fff;
  opacity: 0.5;
}
.qr-text {
  position: relative;
  margin-bottom: 50px;
}
.qr-text-content {
  position: absolute;
  left: calc(50% - 90px);
  z-index: 90;
  width: 180px;
  height: 44px;
  text-align: center;
  color: #b8b8b8;
  font-size: 14px;
  line-height: 22px;
  background-color: #fff;
}
.qr-text-divider {
  position: absolute;
  width: 280px;
  height: 0;
  top: 22px;
  left: calc(50% - 140px);
  border-bottom: 1px solid #b8b8b8;
}
.share-footer {
  position: absolute;
  height: 35px;
  bottom: 0;
  font-size: 14px;
  line-height: 35px;
}
.switch-btn {
  display: flex;
  width: 206px;
  height: 34px;
  padding: 2px;
  margin-bottom: 20px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 30px;
  background: #f2f3f5;
  color: #4e5969;
  div {
    text-align: center;
    cursor: pointer;
  }
  .switch-select {
    background: #ffffff;
    border-radius: 2px;
    color: #165dff;
  }
  .title-class {
    font-size: 14px;
    line-height: 14px !important;
  }
}
.switch-select {
  background: #ffffff;
  border-radius: 2px;
  color: #165dff;
}
.loading {
  margin-top: 45px;
  margin-left: 45px;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-top-color: transparent;
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
