<template>
  <div>
    <div style="height: 450px;overflow: hidden;" v-if="showChse">
      <div class="title">请选择群所在的应用</div>
      <div class="group-list">
        <div
          :class="['item-class', { 'choose-class': item.choose }]"
          v-for="(item, index) in groupListData"
          :key="index"
          @click="chooseGroupChange(item, index)"
        >
          <img :src="item.pic" alt="" />
          <div class="name-class">{{ item.name }}</div>
          <div
            v-if="item.activation != true"
            class="name-class"
            style="margin-left: 10px;font-size:12px;line-height: 33px;"
          >
            (仅内测用户可使用)
          </div>
        </div>
      </div>
    </div>
    <PushSettings
      @goback="goback"
      :dashboardId="dashboardId"
      @changeTitle="changeTitle"
      v-else
    ></PushSettings>
  </div>
</template>

<script>
import PushSettings from "./PushSettings.vue";
export default {
  components: { PushSettings },
  props: {
    haveList: Boolean,
    dashboardId: String
  },
  data() {
    return {
      pushType: "wechat",
      title: "",
      showChse: true,
      groupListData: [
        {
          type: "wechat",
          name: "企业微信",
          pic: "images/app_wechat.svg",
          activation: true,
          choose: true
        },
        {
          type: "dingtalk",
          name: "钉钉",
          pic: "images/app_dingtalk.svg",
          activation: false,
          choose: false
        }
      ]
    };
  },
  created() {
    this.$emit("changeTitle", "选择应用");
  },
  methods: {
    chooseGroupChange(item, index) {
      if (this.groupListData[index].activation == false) {
        return;
      }
      this.pushType = item.type;
      this.showChse = false;
    },
    changeTitle(title) {
      this.$emit("changeTitle", title);
    },
    goback(type) {
      if (type && (type == "submit" || type == "update")) {
        this.$emit("goback", type);
        this.$emit("changeTitle", "当前看板群推送");
      } else {
        this.showChse = true;
        this.$emit("changeTitle", "选择应用");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  margin: 27px 0px 54px 0;
  font-size: 18px;
  color: #000000;
  text-align: center;
}
.group-list {
  // display: flex;
  width: 350px;
  // justify-content: space-between;
  margin: 0 auto;
  img {
    width: 32px;
    height: 32px;
  }
  .name-class {
    margin-left: 20px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
  }
  .item-class {
    display: flex;
    margin-top: 20px;
    width: 350px;
    height: 66px;
    padding: 17px;
    border: 1px solid #c9cdd4;
    border-radius: 8px;
    text-align: center;
    opacity: 0.4;
    cursor: pointer;
  }
  .choose-class:hover {
    opacity: 1;
    border: 2px solid #6aa1ff;
  }
}
</style>
