<template>
  <div class="history-share">
    <div class="divider"></div>
    <div class="history-share-item-outer" v-if="this.dataList.length">
      <HistoryItem
        :historyItem="item"
        v-for="(item, index) in dataList"
        :key="index"
      />
    </div>
    <div class="no-data" v-else>
      <a-empty
        :image="IMAGE_URL"
        :image-style="{ height: '200px' }"
        description="暂无数据"
      />
    </div>
  </div>
</template>

<script>
import HistoryItem from "./components/HistoryItem.vue";
import IMAGE_URL from "@/assets/images/ai_no_task.svg";
import * as API from "@/api/share-ground";

export default {
  components: { HistoryItem },
  props: {
    paramsData: Object,
    dashboardId: String
  },
  data() {
    return {
      IMAGE_URL,
      dataList: [],
      timer: null
    };
  },
  created() {
    this.$emit("changeTitle", "群推送历史记录");
    this.getHistoryList();
    this.timer = setInterval(this.getHistoryList, 5000);
  },
  methods: {
    async getHistoryList() {
      const { data } = await API.getHistoryList(this.paramsData.id);
      this.dataList = data;
    }
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="less" scoped>
.history-share {
  height: 450px;
}
.no-data {
  margin: 0 auto;
  padding-top: 50px;
  font-size: 20px;
  line-height: 28px;
  color: #86909c;
  text-align: center;
}
.history-share-item-outer {
  height: 400px;
  overflow: scroll;
}
.divider {
  height: 0;
  border-bottom: 1px solid #c9cdd4;
}
</style>
