export const frequencyArray = [
  { name: "每日", value: "days" },
  { name: "每周", value: "weeks" },
  { name: "每月", value: "months" }
];
export const weeksArray = [
  { name: "周一", value: "monday" },
  { name: "周二", value: "tuesday" },
  { name: "周三", value: "wednesday" },
  { name: "周四", value: "thursday" },
  { name: "周五", value: "friday" },
  { name: "周六", value: "saturday" },
  { name: "周日", value: "sunday" }
];
export const monthsArray = [
  { name: "1号", value: "1" },
  { name: "2号", value: "2" },
  { name: "3号", value: "3" },
  { name: "4号", value: "4" },
  { name: "5号", value: "5" },
  { name: "6号", value: "6" },
  { name: "7号", value: "7" },
  { name: "8号", value: "8" },
  { name: "9号", value: "9" },
  { name: "10号", value: "10" },
  { name: "11号", value: "11" },
  { name: "12号", value: "12" },
  { name: "13号", value: "13" },
  { name: "14号", value: "14" },
  { name: "15号", value: "15" },
  { name: "16号", value: "16" },
  { name: "17号", value: "17" },
  { name: "18号", value: "18" },
  { name: "19号", value: "19" },
  { name: "20号", value: "20" },
  { name: "21号", value: "21" },
  { name: "22号", value: "22" },
  { name: "23号", value: "23" },
  { name: "24号", value: "24" },
  { name: "25号", value: "25" },
  { name: "26号", value: "26" },
  { name: "27号", value: "27" },
  { name: "28号", value: "28" },
  { name: "29号", value: "29" },
  { name: "30号", value: "30" },
  { name: "31号", value: "31" }
];
