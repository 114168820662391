const GroupMulticolor = "multicolor";
const GroupMonochrome = "monochrome";

const themes = [
  {
    name: "default",
    group: GroupMulticolor,
    palette: [
      "#5470c6",
      "#91cc75",
      "#fac858",
      "#ee6666",
      "#73c0de",
      "#3ba272",
      "#fc8452",
      "#9a60b4",
      "#ea7ccc"
    ]
  },
  {
    name: "vika-default",
    group: GroupMulticolor,
    palette: [
      "rgb(123, 103, 238)",
      "rgb(91, 143, 249)",
      "rgb(205, 221, 253)",
      "rgb(97, 221, 170)",
      "rgb(205, 243, 228)",
      "rgb(101, 120, 155)",
      "rgb(206, 212, 222)",
      "rgb(246, 189, 22)",
      "rgb(111, 94, 249)"
    ]
  },
  {
    name: "feishu-default",
    group: GroupMulticolor,
    palette: [
      "rgb(78, 131, 253)",
      "rgb(80, 206, 251)",
      "rgb(147, 90, 246)",
      "rgb(250, 211, 85)",
      "rgb(247, 105, 100)",
      "rgb(255, 165, 61)"
    ]
  },
  {
    name: "feishu-bright",
    group: GroupMulticolor,
    palette: [
      "rgb(51, 112, 255)",
      "rgb(255, 136, 0)",
      "rgb(20, 192, 255)",
      "rgb(255, 198, 10)",
      "rgb(52, 199, 36)",
      "rgb(143, 149, 158)"
    ]
  },
  // 纯色系列
  {
    name: "blue",
    group: GroupMonochrome,
    palette: [
      "rgb(15, 87, 255)",
      "rgb(43, 107, 255)",
      "rgb(71, 126, 255)",
      "rgb(99, 146, 255)",
      "rgb(128, 166, 255)",
      "rgb(184, 205, 255)"
    ]
  },
  {
    name: "cyan",
    group: GroupMonochrome,
    palette: [
      "rgb(20, 192, 255)",
      "rgb(48, 200, 255)",
      "rgb(75, 207, 255)",
      "rgb(102, 214, 255)",
      "rgb(129, 221, 255)",
      "rgb(184, 236, 255)"
    ]
  },
  {
    name: "green",
    group: GroupMonochrome,
    palette: [
      "rgb(52, 199, 35)",
      "rgb(69, 219, 53)",
      "rgb(99, 225, 84)",
      "rgb(128, 231, 116)",
      "rgb(157, 236, 148)",
      "rgb(215, 247, 212)"
    ]
  },
  {
    name: "purple",
    group: GroupMonochrome,
    palette: [
      "rgb(123, 103, 238)",
      "rgb(139, 122, 240)",
      "rgb(166, 151, 251)",
      "rgb(206, 197, 255)",
      "rgb(220, 214, 255)",
      "rgb(237, 234, 255)"
    ]
  },
  {
    name: "yellow",
    group: GroupMonochrome,
    palette: [
      "rgb(255, 198, 10)",
      "rgb(255, 203, 34)",
      "rgb(255, 209, 58)",
      "rgb(255, 215, 82)",
      "rgb(255, 220, 105)",
      "rgb(255, 231, 153)"
    ]
  },
  {
    name: "orange",
    group: GroupMonochrome,
    palette: [
      "rgb(255, 136, 0)",
      "rgb(255, 149, 29)",
      "rgb(255, 163, 58)",
      "rgb(255, 176, 87)",
      "rgb(255, 190, 116)",
      "rgb(255, 217, 173)"
    ]
  },
  {
    name: "red",
    group: GroupMonochrome,
    palette: [
      "rgb(255, 23, 15)",
      "rgb(255, 50, 43)",
      "rgb(255, 78, 71)",
      "rgb(255, 105, 99)",
      "rgb(255, 132, 128)",
      "rgb(255, 186, 184)"
    ]
  },
  {
    name: "brown",
    group: GroupMonochrome,
    palette: [
      "rgb(110, 56, 45)",
      "rgb(120, 70, 60)",
      "rgb(142, 100, 91)",
      "rgb(180, 152, 146)",
      "rgb(209, 191, 188)",
      "rgb(232, 223, 221)"
    ]
  },
  {
    name: "black",
    group: GroupMonochrome,
    palette: [
      "rgb(0, 0, 0)",
      "rgb(34, 34, 34)",
      "rgb(68, 68, 68)",
      "rgb(102, 102, 102)",
      "rgb(136, 136, 136)",
      "rgb(204, 204, 204)"
    ]
  }
];

export default {
  themes,
  getColors(themeName) {
    let defaultTheme = themes[0];
    for (const item of themes) {
      if (item.name === "default") {
        defaultTheme = item;
      }
      if (item.name === themeName) {
        return item.palette;
      }
    }
    return defaultTheme.palette;
  }
};
