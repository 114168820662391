import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("") });

interceptor.auth(axios);

//获取连接器列表
export function getConnectorList() {
  return axios.get(`api/dataops/connector/list`);
}
export function getConnectionList() {
  return axios.get(`api/dataops/connection/list`);
}
//获取连接器详情
export function getDestinationList(id) {
  return axios.get(`api/dataops/connector/${id}`);
}

//保存推送设置
export function saveSchedule(data) {
  return axios.post("/api/datasheet/app/push", data);
}

//获取看板内容
export function getDashboard(dashboardId) {
  return axios.get(`/api/dashboard/${dashboardId}`);
}

//分享接口
export function getShareUrl(dashboardId) {
  return axios.get(`/api/dashboard/${dashboardId}/shared`);
}

//获取看板群推送
export function getDeliveryList(dashboardId) {
  return axios.get(`/api/dashboard/delivery/list/${dashboardId}`);
}

//获取看板群推送
export function getDeliveryListStutas() {
  return axios.get(`/api/datasheet/app/push/status`);
}

//获取看板群推送 推送历史
export function getHistoryList(scheduleId) {
  return axios.get(`api/datasheet/app/push/tasks?id=${scheduleId}`);
}

//获取看板群推送 单个历史 详细记录
export function getTaskLog(logsId) {
  return axios.get(`api/datasheet/app/push/task/log/${logsId}`);
}

//立即运行
export function runDatasheetApp(pushId) {
  return axios.get(`/api/datasheet/app/push/run/${pushId}`);
}

//删除推送
export function deleteDatasheetConnection(connectionId) {
  return axios.delete(`/api/datasheet/app/push/${connectionId}`);
}
