<template>
  <div style="margin-bottom: 8px; text-align: left">
    <a-dropdown :trigger="['click']">
      <a>
        <strong>{{ value | localeType }}</strong>
        <a-icon type="caret-down" />
      </a>
      <a-menu slot="overlay" @click="onSelect">
        <a-menu-item v-for="item in ['none', 'login']" :key="item">
          {{ item | localeType }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <span style="color: rgba(0, 0, 0, 0.45); margin-left: 8px">
      {{ value | localeDesc }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  filters: {
    localeType(type) {
      const map = {
        none: "所有人可见",
        login: "登陆后可见"
      };
      return map[type];
    },
    localeDesc(type) {
      const map = {
        none: "任何人获取到分享链接后都能查看其内容",
        login: "登陆后才能查看内容"
      };
      return map[type];
    }
  },
  methods: {
    onSelect(evt) {
      if (this.value == evt.key) {
        return;
      }
      this.$confirm({
        title: "切换分享方式",
        content: "切换后原分享链接将会失效，是否继续切换？",
        okType: "danger",
        onOk: () => {
          this.$emit("input", evt.key);
          this.$emit("change", evt.key);
        }
      });
    }
  }
};
</script>
