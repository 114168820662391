<template>
  <div class="pane-header">
    <div class="title">
      <Title v-model="widget.title">
        <i class="ndl-icon-edit-line ndl-color-gray ndl-margin-left-sm"></i>
      </Title>
    </div>
    <Focusable size="large" @click="$emit('cancel')">
      <i class="ndl-icon-x-circle"></i>
      <span>取消</span>
    </Focusable>
    <Focusable size="large" @click="$emit('confirm')">
      <i class="ndl-icon-save"></i>
      <span>保存</span>
    </Focusable>
  </div>
</template>

<script>
import { NodeTitle as Title } from "@/views/datamodel/components/workspace-header";
import Focusable from "@/views/datamodel/components/focusable";

export default {
  inheritAttrs: false,
  components: { Title, Focusable },
  props: {
    widget: Object
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.pane-header {
  flex-shrink: 0;
  height: @pane-header-height;
  border-bottom: @pane-border;
  display: flex;
  align-items: center;
  padding: 0 16px;
  .title {
    flex: 1 1 0;
    overflow: hidden;
    font-size: 18px;
    color: #1f2329;
  }
  .title-stage,
  .title-input {
    font-size: 16px;
    padding: 2px 8px;
  }
  .focusable {
    width: auto;
    flex-shrink: 0;
    padding: 0 8px;
    font-size: 14px;
    margin-left: 1em;
    i {
      margin-right: 6px;
    }
  }
}
</style>
