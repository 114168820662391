<template>
  <div class="share">
    <div class="share-top">
      <div class="share-top-title">微信公众号定时推送</div>
      <div style="margin-left:auto;margin-right:50px">
        <div @click="changeToShowPage(3)" class="share-top-user">
          <div style="display:flex" v-if="showUserList.length">
            <div
              class="share-top-user-one"
              v-for="(item, index) in showUserList"
              :key="index"
            >
              <img :src="item.detail.headImgUrl" alt="" />
            </div>
            <div
              v-if="lastUserNum > 0"
              class="share-top-user-one share-more-num"
            >
              +{{ lastUserNum }}
            </div>
          </div>
          <div style="line-height:23px" v-else>暂无用户</div>
          <a-icon
            style="margin-left:10px;line-height:32px;color:#C9CDD4"
            type="right"
          />
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script>
import * as API from "@/api/dashboard";

export default {
  props: {
    dashboardId: String
  },
  data() {
    return {
      userList: [],
      showUserList: [],
      lastUserNum: 0
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const { data } = await API.getQrcodeUsersData(this.dashboardId);
      this.userList = data;
      if (this.userList.length > 3) {
        this.showUserList = this.userList.slice(0, 3);
        this.lastUserNum =
          this.userList.length - 3 > 99 ? 99 : this.userList.length - 3;
      } else {
        this.showUserList = this.userList;
      }
    },
    changeToShowPage(index) {
      this.$emit("changeShowPage", index);
    }
  }
};
</script>
<style lang="less" scoped>
.share-top {
  display: flex;
  padding: 0 16px 0px;
  border-bottom: 1px solid #c9cdd4;
}
.share-top-title {
  font-weight: 600;
  font-size: 18px;
  height: 36px;
}
.share-top-user {
  display: flex;
  cursor: pointer;
}
.share-top-user-one {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  font-size: 12px;
  overflow: hidden;
}
.share-top-user-one img {
  width: 100%;
  height: 100%;
}
.share-more-num {
  text-align: center;
  line-height: 24px;
}
.divider {
  height: 0;
}
</style>
