export default function(error) {
  const defaultMsg = "读取数据失败";
  if (error.data) {
    const message = error.data.msg || defaultMsg;
    const detail = error.data.detail;
    return { message, detail };
  }
  if (error.response) {
    const message = error.response.statusText || defaultMsg;
    const detail = "";
    return { message, detail };
  }
  if (error.error) {
    const message = error.error.message || defaultMsg;
    const detail = "";
    return { message, detail };
  }
  return { message: defaultMsg, detail: "" };
}
